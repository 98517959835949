import React from 'react'
import { Link ,useLocation } from 'react-router-dom';
import "./Banner.css";
import config from '../../config';

export default function Banner() {
	 const location = useLocation();

    const profileData = JSON.parse(localStorage.getItem("profileData"));
    console.log(profileData);


    return (
        <>
		{ location.pathname === '/home' && (
			<div className='d-flex justify-content-center align-items-center' >
				{/* dedicated link based on therapy */}
				<a target="_blank"  rel="noreferrer noopener" href={profileData.feedback_link} className='ml-1'><img src={`${config.CUSTOM_IMAGES_URL}/`+"feedback.png"} alt="feedback" /> <br /></a>
				{/* dedicated link based on therapy */}
			</div>
		)
		}
			
            <Link to="/therapies" className='p-4 bannerBox'>
                <img src={`${config.CUSTOM_IMAGES_URL}/`+"ordersamples.png"} alt="therapies" /> <br />
            </Link>
			<Link to="/articles" className='p-4 bannerBox'>
                <img src={`${config.CUSTOM_IMAGES_URL}/`+"banner.png"} alt="banner" /> <br />
            </Link>
			
			{profileData.member_role !== '24' && (
        /* only for company executives, not for doctors */
        <>
            <a target="_blank" rel="noreferrer noopener" href={profileData.campaign_link} className='p-4 bannerBox'>
                <img src={`${config.CUSTOM_IMAGES_URL}/posterapi.png`} alt="posterapi" /><br />
            </a>
        </>
        /* only for company executives, not for doctors */
    )}
            <div className='p-4 bannerBox'>
                <img src={`${config.CUSTOM_IMAGES_URL}/${profileData.therapeutic_focus}.jpg`} alt="BrandPanel"/>            
            </div>
			<div className='p-4 bannerBox'>
                <a target="_blank" href="https://bit.ly/RPGLS-IG" > <img class="mx-2" src={`${config.CUSTOM_IMAGES_URL}/`+"instagram2x.png"} alt="instagram" style={{height:'45px'}} />  
				</a>
            
                <a target="_blank" href="https://bit.ly/RPGLS-Linkedin" ><img class="mx-2" src={`${config.CUSTOM_IMAGES_URL}/`+"linkedin2x.png"} alt="linkedin" style={{height:'45px'}} /> 
</a>				
            
               <a target="_blank" href="https://bit.ly/RPGLS-FB" ><img class="mx-2" src={`${config.CUSTOM_IMAGES_URL}/`+"facebook2x.png"} alt="twitter" style={{height:'45px'}} />   </a>    
            
                <a target="_blank" href="https://bit.ly/RPGLS" ><img class="mx-2" src={`${config.CUSTOM_IMAGES_URL}/`+"www.png"} alt="therapies" style={{height:'45px'}} />   </a>      
            </div>
			
        </>
    )
}